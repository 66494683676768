import { createSlice } from '@reduxjs/toolkit';

let sidebarShow = window.localStorage.sidebarShow;
if (!sidebarShow || sidebarShow === 'true') {
	sidebarShow = true;
} else {
	sidebarShow = false;
}

const initialState = {
	sidebarShow,
};

const sidebarSlice = createSlice({
	name: 'sidebar',
	initialState,
	reducers: {
		toggleSidebar: (state, action) => {
			window.localStorage.setItem('sidebarShow', action.payload);
			state.sidebarShow = action.payload;
		},
	},
});

export const { toggleSidebar } = sidebarSlice.actions;

export default sidebarSlice.reducer;
