import $axios from './api';

export const getUser = async () => {
	const { data } = await $axios.get('/v1/admin/profile');
	return data;
};

export const getParameters = async () => {
	const { data } = await $axios.get('/v1/admin/parameters');
	return data;
};

export const setParameters = async (sendData) => {
	const { data } = await $axios.post('/v1/admin/parameters', sendData);
	return data;
};