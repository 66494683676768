import $axios from './api';

export const list = async () => {

	const { data } = await $axios.post('/v1/admin/author/confirm/list', { status: 'pending' });
	return data;

};

export const confirm = async (sendData) => {

	const { data } = await $axios.post('/v1/admin/author/confirm', sendData);
	return data;

};