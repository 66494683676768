import $axios from './api';

export const list = async (sendData) => {

	const { data } = await $axios.post('/v1/admin/publication/list', sendData);
	return data;

};

export const getById = async (id) => {

	const { data } = await $axios.get(`/v1/admin/publication/${id}`);
	return data;

};

export const edit = async (id, sendData) => {

	const { data } = await $axios.post(`/v1/admin/publication/${id}`, sendData);
	return data;

};

export const remove = async (id) => {

	const { data } = await $axios.delete(`/v1/admin/publication/${id}`);
	return data;

};