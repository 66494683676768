import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	show: false,
	props: {
		color: '',
		title: '',
		description: '',
	},
};

const toastSlice = createSlice({
	name: 'toast',
	initialState,
	reducers: {
		showToast: (state, action) => {
			state.show = true;
			state.props = {
				color: action.payload.color,
				title: action.payload.title,
				description: action.payload.description,
			};
		},
		showSuccessToast: (state, action) => {
			state.show = true;
			state.props = {
				color: 'success',
				title: action.payload.title,
				description: action.payload.description,
			};
		},
		showErrorToast: (state, action) => {

			state.show = true;
			state.props = {
				color: 'danger',
				title: action.payload.title,
				description: action.payload.description,
			};
		},
		closeToast: (state) => {
			state.show = false;
			state.props = {
				color: '',
				title: '',
				description: '',
			};
		},
	},
});

export const { showToast, closeToast, showSuccessToast, showErrorToast } = toastSlice.actions;

export default toastSlice.reducer;