import { createSlice } from '@reduxjs/toolkit';
import api from 'src/api/index';
import { showErrorToast } from './toast';

const initialState = {
	list: [],
};

const lecturesSlice = createSlice({
	name: 'lectures',
	initialState,
	reducers: {
		setLecturesList: (state, action) => {
			state.list = action.payload;
		},
		addLecturesItem: (state, action) => {
			state.list.push(action.payload);
		},
		removeLecturesItem: (state, action) => {
			state.list = state.list.filter((item) => action.payload !== item.id);
		},
	},
});

export const { setLecturesList, addLecturesItem, removeLecturesItem } = lecturesSlice.actions;

export const fetchLecturesList = () => async (dispatch) => {
	try {

		const res = await api.lecturesList();
		dispatch(setLecturesList(res.list));

	} catch (error) {

		dispatch(showErrorToast({
			title: 'Ошибка',
			description: 'Не удалось получить лекции'
		}));
		
	}
};

export const getLectureById = (id) => ( dispatch, getState ) => {

	return new Promise(( resolve, reject ) => {

		const state = getState();

		const list = state.lectures.list;

		let item = list.find(item => item.id === id);

		if (item) {

			resolve(item);

		} else {

			api.lectureById(id)
				.then(res => {
					resolve(res);
				})
				.catch(() => {

					dispatch(showErrorToast({
						title: 'Ошибка',
						description: `Лекция ${id} не найдена`
					}));

					reject();

				});
		}

	});

};

export default lecturesSlice.reducer;
