import $axios from './api';

export const list = async (sendData = {}) => {

	const { data } = await $axios.post('/v1/admin/authorPayment/list', sendData);
	return data;

};

export const markPayment = async (paymentId) => {
	
	const { data } = await $axios.post(`/v1/admin/authorPayment/process/${paymentId}`);
	return data;

};