import axios from 'axios';

const HOST = '/api';
const $axios = axios.create({
	baseURL: HOST,
	withCredentials: true,
});

$axios.interceptors.response.use(
	function (response) {
		return response;
	},
	function (error) {
		if (!!error.response && error.response.status === 401) {
			window.location.href = '#/login';
		}
		return Promise.reject(error);
	}
);

export default $axios;
