import { createSlice } from '@reduxjs/toolkit';
import api from 'src/api/index';
import { showErrorToast } from './toast';

const initialState = {
	list: [],
	total: 0,
	loading: false
};

const paymentsSlice = createSlice({
	name: 'payments',
	initialState,
	reducers: {
		setPaymentsList: (state, action) => {
			state.list = action.payload.list;
			state.total = action.payload.count;
		},
		addPaymentsItem: (state, action) => {
			state.list.push(action.payload);
		},
		removePaymentsItem: (state, action) => {
			state.list = state.list.filter((item) => action.payload !== item.id);
		},
		setPaymentsLoading: (state, action) => {
			state.loading = action.payload;
		}
	},
});

export const { setPaymentsList, addPaymentsItem, removePaymentsItem, setPaymentsLoading } = paymentsSlice.actions;

export const getPaymentsList = (sendData = {}) => async (dispatch) => {

	dispatch(setPaymentsLoading(true));

	try {
		const res = await api.getPaymentsList(sendData);
		dispatch(setPaymentsList(res));

	} catch (error) {

		console.error(error);

		dispatch(showErrorToast({
			title: 'Ошибка',
			description: 'Ошибка с получение списка платежных поручений'
		}));

	} finally {

		dispatch(setPaymentsLoading(false));

	}

};

export const markPaymentAsPaid = (paymentId) => async (dispatch) => {

	try {

		const res = await api.markPaymentAsPaid(paymentId);
		console.log(res);

	} catch (error) {

		console.error(error);

		dispatch(showErrorToast({
			title: 'Ошибка',
			description: 'Ошибка с подтверждением платежного поручения'
		}));

	}

};

export default paymentsSlice.reducer;