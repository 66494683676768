import { createSlice } from '@reduxjs/toolkit';
import api from 'src/api/index';
import { showErrorToast } from './toast';

const initialState = {
	authorConfirmation: [],
	publicationApproval: []
};

const moderatorSlice = createSlice({
	initialState,
	name: 'moderator',
	reducers: {
		setAuthorConfirmationList: (state, action) => {
			state.authorConfirmation = action.payload;
		},
		sePublicationApprovalList: (state, action) => {
			state.publicationApproval = action.payload;
		},
	},
});

export const { setAuthorConfirmationList, sePublicationApprovalList } = moderatorSlice.actions;

export const fetchAuthorConfirmationList = () => async ( dispatch, getState ) => {

	const state = getState();

	const list = state.moderator.authorConfirmation;

	if ( !list.length ) {

		try {

			const res = await api.commentsByType('authorConfirmation');
			
			if (res) {

				dispatch(setAuthorConfirmationList(res));

			} else {
				
				throw Error();

			}

		} catch(error) {

			dispatch(showErrorToast({
				title: 'Ошибка',
				description: 'Не удалось получить комментарии'
			}));

		}

	} 	

};

export const fetchPublicationApproval = () => async ( dispatch, getState ) => {

	const state = getState();

	const list = state.moderator.publicationApproval;

	if ( !list.length ) {

		try {

			const res = await api.commentsByType('publicationApproval');
			
			if (res) {

				dispatch(sePublicationApprovalList(res));

			} else {
				
				throw Error();

			}

		} catch(error) {

			dispatch(showErrorToast({
				title: 'Ошибка',
				description: 'Не удалось получить комментарии'
			}));

		}

	} 	

};

export default moderatorSlice.reducer;