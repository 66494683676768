
import { configureStore } from '@reduxjs/toolkit';
import sidebar from './sidebar';
import toast from './toast';
import auth from './auth';
import modal from './modal';
import registerRequests from './registerRequests';
import purchases from './purchases';
import publications from './publications';
import courses from './courses';
import lectures from './lectures';
import moderator from './moderator';
import users from './users';
import payments from './payments';


const store = configureStore({
	reducer: {
		sidebar,
		toast,
		auth,
		modal,
		registerRequests,
		purchases,
		publications,
		courses,
		lectures,
		moderator,
		users,
		payments
	},
});
export default store;
