import { createSlice } from '@reduxjs/toolkit';
import api from 'src/api/index';
import { showErrorToast } from './toast';

const initialState = {
	list: [],
	total: 0,
	loading: false
};

const publicationsSlice = createSlice({
	name: 'publications',
	initialState,
	reducers: {
		setPublicationsList: (state, action) => {
			state.list = action.payload.list;
			state.total = action.payload.count;
		},
		addPublicationsItem: (state, action) => {
			state.list.push(action.payload);
		},
		removePublicationsItem: (state, action) => {
			state.list = state.list.filter((item) => action.payload !== item.id);
		},
		updatePublication: (state, action) => {
			const index = state.list.findIndex(item => item.id === action.payload.id);
			state.list.splice(index, 1, action.payload);
		},
		setPublicationLoading: (state, action) => {
			state.loading = action.payload;
		}
	},
});

export const { setPublicationsList, addPublicationsItem, removePublicationsItem, setPublicationLoading} = publicationsSlice.actions;

export const fetchPublicationsList = (sendData = {}) => async (dispatch) => {
	try {
		
		dispatch(setPublicationLoading(true));
		const res = await api.publicationsList(sendData);
		dispatch(setPublicationsList(res));

	} catch (error) {

		dispatch(showErrorToast({
			title: 'Ошибка',
			description: 'Не удалось получить публикации'
		}));
		
	} finally {
		
		dispatch(setPublicationLoading(false));
	
	}
};

export const getPublicationById = (id) => ( dispatch, getState ) => {

	return new Promise(( resolve, reject ) => {
		
		const state = getState();

		const list = state.publications.list;

		let item = list.find(item => item.id === id);

		if (item) {

			resolve(item);

		} else {

			api.publicationById(id)
				.then(res => {
					resolve(res);
				})
				.catch(() => {

					dispatch(showErrorToast({
						title: 'Ошибка',
						description: `Публикация ${id} не найдена`
					}));

					reject();

				});
		}

	});

};

export const editPublication = (id, sendData) => async (dispatch) => {

	try {

		dispatch(setPublicationLoading(true));
		
		const res = await api.publicationEdit(id, sendData);

		if (res) {

			dispatch(removePublicationsItem(id));

		}

	} catch (error) {

		console.error(error);

		dispatch(showErrorToast({
			title: 'Ошибка',
			description: 'Ошибка с верификацией публикации'
		}));

	} finally {

		dispatch(setPublicationLoading(false));

	}

};

export default publicationsSlice.reducer;
