import { createSlice } from '@reduxjs/toolkit';
import api from 'src/api/index';
import { showErrorToast } from './toast';

const initialState = {
	authUser: null,
};

const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		setAuthUser: (state, action) => {
			state.authUser = action.payload;
		},
	},
});

export const { setAuthUser } = authSlice.actions;

export const getAuthUser = () => async (dispatch) => {

	try {

		const user = await api.getUser();

		if (user) {

			dispatch(setAuthUser(user));
		}

	} catch (error) {

		dispatch(showErrorToast({
			title: 'Ошибка',
			description: 'Пользователь не найден'
		}));

		return Promise.reject(error);
		
	}
};


export default authSlice.reducer;
