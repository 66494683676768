import * as auth from './auth';
import * as admin from './admin';
import * as publications from './publications';
import * as lectures from './lectures';
import * as courses from './courses';
import * as registerRequests from './register-requests';
import * as moderator from './moderator';
import * as users from './users';
import * as payments from './payments';
import * as purchases from './purchases';

const api = {
	
	// auth
	login: auth.login,
	getUser: admin.getUser,
	logout: auth.logout,
	forgotPassword: auth.forgotPassword,
	resetPassword: auth.resetPassword,

	//parameters
	getParameters: admin.getParameters,
	setParameters: admin.setParameters,

	// publication
	publicationsList: publications.list,
	publicationById: publications.getById,
	publicationRemove: publications.remove,
	publicationEdit: publications.edit,
	
	// lecture
	lecturesList: lectures.list,
	lectureById: lectures.getById,
	lectureRemove: lectures.remove,
	lectureEdit: lectures.edit,
	
 	// courses
	coursesList: courses.list,
	courseById: courses.getById,
	courseRemove: courses.remove,
	courseEdit: courses.edit,

	//register-requests
	registerRequestsList: registerRequests.list,
	registerRequestsConfirm: registerRequests.confirm,

	//moderator
	commentsByType: moderator.commentsByType,

	// users
	usersList: users.list,
	userById: users.getById,
	userEdit: users.update,

	// payments
	getPaymentsList: payments.list,
	markPaymentAsPaid: payments.markPayment,

	// purchase
	getPurchasesList: purchases.list

};

export default api;
