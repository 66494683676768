import { createSlice } from '@reduxjs/toolkit';
import api from 'src/api/index';
import { showErrorToast } from './toast';

const initialState = {
	list: [],
};

const registerRequestsSlice = createSlice({
	name: 'registerRequests',
	initialState,
	reducers: {
		setRegisterRequestsList: (state, action) => {
			state.list = action.payload;
		},
		addRegisterRequestsListItem: (state, action) => {
			state.list.push(action.payload);
		},
		removeRegisterRequestsListItem: (state, action) => {
			state.list = state.list.filter((item) => action.payload !== item.id);
		},
	},
});

export const fetcbRegisterRequestList = () => async (dispatch) => {
	
	try {

		const res = await api.registerRequestsList();
		dispatch(setRegisterRequestsList(res.list));

	} catch (error) {

		dispatch(showErrorToast({
			title: 'Ошибка',
			description: 'Не удалось получить заявки'
		}));
		
	}
};

export const confirmRegisterRequest = ( confirmData ) => async (dispatch) => {

	try {
		
		const res = await api.registerRequestsConfirm( confirmData );

		if (res) {

			dispatch( removeRegisterRequestsListItem( confirmData.id ) );

		}

	} catch (error) {

		console.error(error);

		dispatch(showErrorToast({
			title: 'Ошибка',
			description: 'Не удалось выполнить запрос заявки'
		}));

	}

};

export const { setRegisterRequestsList, addRegisterRequestsListItem, removeRegisterRequestsListItem } =
	registerRequestsSlice.actions;

export default registerRequestsSlice.reducer;