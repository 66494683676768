import $axios from './api';

export const login = async ({ email, password }) => {
	const { data } = await $axios.post('/v1/auth/login', { email, password });
	return data;	
};

export const logout = async () => {

	const { data } = await $axios.post('/v1/auth/logout');
	return data;

};

export const forgotPassword = async (email) => {
	const { data } = await $axios.post('/v1/admin/forgotPassword', { email });
	return data;
};

export const resetPassword = async ( sendData ) => {
	const { data } = await $axios.post('/v1/admin/resetPassword', sendData );
	return data;
};