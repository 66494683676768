import $axios from './api';

// sendData example
// {
//   "email": "xxx@xx.xx",
//   "fullName": "string",
//   "isAuthor": true,
//   "limit": 0,
//   "offset": 0,
//   "page": 0,
//   "registeredAfter": "string",
//   "registeredBefore": "string",
//   "sortBy": "string",
//   "sortDirection": "string"
// }

export const list = async (sendData = {}) => {
	const { data } = await $axios.post('/v1/admin/user/list', sendData);
	return data;
};

export const getById = async (userId) => {
	const { data } = await $axios.get(`/v1/admin/user/${userId}`);
	return data;
};

export const update = async (userId, sendData) => {
	const { data } = await $axios.post(`/v1/admin/user/${ userId }`, sendData);
	return data;
};