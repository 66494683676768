import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	isOpen: false,
	name: '',
	props: null,
};

const modalSlice = createSlice({
	initialState,
	name: 'modal',
	reducers: {
		openModal: (state, action) => {
			state.isOpen = true;
			state.name = action.payload.name;
			state.props = action.payload.props;
		},
		closeModal: (state) => {
			state.isOpen = false;
			state.name = '';
			state.props = null;
		},
	},
});

export const { openModal, closeModal } = modalSlice.actions;

export default modalSlice.reducer;