import $axios from './api';

export const list = async () => {

	const { data } = await $axios.post('/v1/admin/course/list', {});
	return data;

};

export const getById = async (id) => {

	const { data } = await $axios.get(`/v1/admin/course/${id}`);
	return data;

};

export const edit = async (id) => {

	const { data } = await $axios.post(`/v1/admin/course/${id}`);
	return data;

};

export const remove = async (id) => {

	const { data } = await $axios.delete(`/v1/admin/course/${id}`);
	return data;

};