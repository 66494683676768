import { createSlice } from '@reduxjs/toolkit';
import api from 'src/api/index';
import { showErrorToast } from './toast';

const initialState = {
	list: [],
	total: 0,
};

const usersSlice = createSlice({
	name: 'users',
	initialState,
	reducers: {
		setUsersList: (state, action) => {
			state.list = action.payload.list;
			state.total = action.payload.total;
		},
		addUserItem: (state, action) => {
			state.list.push(action.payload);
		},
		updateUserItem: (state, action) => {
			const index = state.list.findIndex(item => item.id === action.payload.id);
			state.list.splice(index, 1, action.payload);
		},
		removeUserItem: (state, action) => {
			state.list = state.list.filter((item) => action.payload !== item.id);
		},
	},
});

export const { setUsersList, addUserItem, removeUserItem, updateUserItem } = usersSlice.actions;

export const getUsersList = (sendData = {}) => async (dispatch) => {

	try {

		const data = await api.usersList(sendData);
		dispatch(setUsersList(data));

	} catch (error) {

		console.error(error);
		dispatch(showErrorToast({
			description: 'Не удалось получить список пользователей'
		}));

	}

};

export const getUserById = (userId) => (dispatch, getState) => {

	return new Promise((resolve, reject) => {

		const state = getState();

		const usersList = state.users.list;

		const result = usersList.find(user => user.id === userId);

		if (result) {

			return result;

		} else {

			api.userById(userId)
				.then(res => {
					dispatch(addUserItem(res));
					resolve(res);
				})
				.catch(() => {

					dispatch(showErrorToast({
						title: 'Ошибка',
						description: `Пользователь ${userId} не найден`
					}));

					reject();

				});
		}
	});

};

export const editUser = (userId, sendData) => async (dispatch, getState) => {

	try {

		const res = await api.userEdit(userId, sendData);

		if (res) {

			dispatch(updateUserItem({
				...sendData,
				id: userId
			}));

		}

	} catch(error) {

		console.error(error);
		showErrorToast({
			description: 'Ошибка при изменении пользователя'
		});
	}
	
};

export default usersSlice.reducer;

