import { createSlice } from '@reduxjs/toolkit';
import api from 'src/api/index';
import { showErrorToast } from './toast';

const initialState = {
	list: [],
};

const lecturesSlice = createSlice({
	name: 'courses',
	initialState,
	reducers: {
		setCoursesList: (state, action) => {
			state.list = action.payload;
		},
		addCoursesItem: (state, action) => {
			state.list.push(action.payload);
		},
		removeCoursesItem: (state, action) => {
			state.list = state.list.filter((item) => action.payload !== item.id);
		},
	},
});

export const { setCoursesList, addCoursesItem, removeCoursesItem } = lecturesSlice.actions;

export const fetchCourseList = () => async (dispatch) => {
	try {

		const res = await api.coursesList();
		dispatch(setCoursesList(res.list));

	} catch (error) {

		dispatch(showErrorToast({
			title: 'Ошибка',
			description: 'Не удалось получить курсы'
		}));
		
	}
};

export const getCourseById = (id) => ( dispatch, getState ) => {

	return new Promise(( resolve, reject ) => {

		const state = getState();

		const list = state.courses.list;

		let item = list.find(item => item.id === id);

		if (item) {

			resolve(item);

		} else {

			api.courseById(id)
				.then(res => {
					resolve(res);
				})
				.catch(() => {

					dispatch(showErrorToast({
						title: 'Ошибка',
						description: `Курс ${id} не найден`
					}));

					reject();

				});
		}

	});

};

export default lecturesSlice.reducer;
